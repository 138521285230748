@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.GroupScheduleSmall {
  display: grid;
  grid-template-columns: auto repeat(7, 1fr);
  text-align: center;
  //font-size: 0.8em;
  //background: repeating-linear-gradient(45deg, lightpink, lightpink 10px, white 0, white 20px);
  background: lavender;
  overflow-x: auto;

  .group {
    color: white;
    border-radius: 5px;

    //font-weight: bold !important;
    font-size: 0.8em;
    overflow: hidden;

    &.bg-success-temp {
      background: repeating-linear-gradient(
        45deg,
        var(--success),
        var(--success) 5px,
        #14d0647f 0,
        #14d0647f 10px
      );
    }
    &.bg-info-temp {
      background: repeating-linear-gradient(
        45deg,
        var(--info),
        var(--info) 5px,
        #2bbbec7f 0,
        #2bbbec7f 10px
      );
    }
    &.bg-danger-temp {
      background: repeating-linear-gradient(
        45deg,
        var(--danger),
        var(--danger) 5px,
        #ec4e7a7f 0,
        #ec4e7a7f 10px
      );
    }
  }

  .guide-cell {
    min-width: 120px;
    background: WhiteSmoke;
    &.highlight {
      background: theme-color-level(primary, -10);
    }
  }

  .time-cell {
    min-width: 30px !important;
    background: WhiteSmoke;
    &.highlight {
      background: theme-color-level(primary, -10);
    }
  }

  > * {
    border: 1px solid white;
  }

  .cell-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    font-size: 7em;
    opacity: 0.15;
    transform: rotate(10deg);
    &:hover {
      opacity: 0.25;
    }
  }
}
