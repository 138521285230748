.LoginModal {
  .modal-body {
    // background: linear-gradient(-10deg, white 50%, var(--primary) 80%);

    h1 {
      font-size: 1.1em;
      text-transform: uppercase;
      font-family: "jost";
      color: #ccc;
    }
  }

  .login-header {
    .logo-img {
      height: 100px;
      margin-bottom: 5px;
    }

    // color: white;
    font-size: 1.1em;
    font-weight: bold;
    padding: 100px 0;
    color: var(--primary);
    font-family: "jost";

    
  }
}