@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.CrudTable {
	.table {
		border: 1px solid #eee;
		display: grid;

		@include media-breakpoint-down(sm) {
			grid-template-columns: auto 1fr;

			> * {
				padding: 5px;
      }
      
      .buttons {
        grid-column: 1 / span 2;
      }
		}

    @include media-breakpoint-up(md) {
			grid-template-columns: repeat(var(--num-cols), auto);

			> * {
				border-bottom: 1px solid #eee;
				padding: 10px 5px;
			}
		}
	}
}
