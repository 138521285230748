@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.OrgChart {
  img {
    height: 27px;
  }
  .google-visualization-orgchart-node {
    // background: var(--dark);
    // color: var(--light);
    // font-size: 1em;
    // border: none;
    // padding: 10px 20px;
    font-family: "Jost";
    font-size: 0.8em;
    font-weight: bold;
    color: #bbb;
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    height: 100%;

    // &.google-visualization-orgchart-nodesel {
    //   .card {
    //     background: #eee;
    //   }
    // }

    .border {
      border-width: 5px !important;
    }

    .card {
      padding: 0 40px;
      @include media-breakpoint-down(sm) {
        padding: 0;
      }

      &.enrolled {
        background-color: adjust-color($green, $lightness: 50%);
      }
    }
  }
  .google-visualization-orgchart-linenode {
    border-color: #ddd;
    border-width: 0.5px;
  }

  .card {
    &:hover {
      background-color: #eee;
    }
  }
}
