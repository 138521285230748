// https://www.npmjs.com/package/react-pro-sidebar

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

$sidebar-bg-color: var(--dark);
$icon-bg-color: lighten(#1f262e, 10%);
$submenu-bg-color: lighten(#1f262e, 10%);
$submenu-bg-color-collapsed: #2b2b2b !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar > .pro-sidebar-inner {
  overflow-y: hidden;
}

.StudentNav {
  height: 100vh;
  z-index: 20;
  @include media-breakpoint-down(xs) {
    height: auto;
    .fixedInner {
      position: relative !important;
      height: auto;

      .pro-sidebar {
        width: 100vw;
      }
      .pro-sidebar-footer {
        display: none;
      }
    }
  }
  transition-duration: 0.3s;

  .fixedInner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }

  .popper-inner {
    background-color: lighten(#1f262e, 10%) !important;
  }
  .popper-arrow {
    border-left: 7px solid var(--dark) !important;
  }
}
