.DailyReport {
  .daily-report-table {
    grid-template-columns: 45px 80px 150px 160px 1fr 45px;

    > * {
      min-height: 70px !important;
    }

    ::placeholder {
      color: #bbb;
    }
  }
}
