.NotesRichEditorLite {
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  em {
    color: var(--pink);
    opacity: 0.9;
  }
  strong {
    color: var(--secondary);
  }

  h1 {
    font-size: 1.7em;
    margin-top: 50px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  h2 {
    margin-top: 35px;
    font-size: 1.5em;
  }
  h3 {
    margin-top: 20px;
    font-size: 1.3em;
  }
}
