@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700;800&family=Roboto+Mono&display=swap");

.Main {
  font-family: "Jost", monospace;
}

.btn {
  font-family: "Jost", monospace;
}

.fullscreen-centered {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

// button that looks like a
.btn-a {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--dark);
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

// a that looks like a button

// etc
.no-selection {
  user-select: none;
}

.modal-xxl {
  max-width: 80vw;
}

.white-space-pre {
  white-space: pre-wrap;
}

.highlight-onhover:hover {
  background: pink;
}

.revealOnHoverTd {
  * {
    opacity: 0;
  }

  &:hover * {
    opacity: 1;
  }
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-10 {
  opacity: 0.1;
}
